<template>
  <div>
    <el-button type="primary" icon="el-icon-plus" @click="showDialog" v-if="!disabled">选择方案</el-button>
    <div class="info" v-if="select">
      <el-form>
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="方案编码" label-width="100px">
              <el-input v-model="select.schemeCode" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="方案名称" label-width="100px">
              <el-input v-model="select.schemeName" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="方案类型" label-width="100px">
              <el-input v-model="select.schemeTypeDesc" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="门店类型" label-width="100px">
              <el-input v-model="select.terminalTypeDesc" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="陈列类型" label-width="100px">
              <el-input v-model="select.displayTypeDesc" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="方案执行时间" label-width="100px">
              <el-input v-model="select.schemeTime" readonly></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="方案描述" label-width="100px">
              <el-input type="textarea" v-model="select.schemeDesc" readonly></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-for="(item, index) in select.actSchemeSetmealList || []">
        <div class="item" :key="index">
          <div class="item-name">套餐{{index+1}}</div>
          <el-form>
            <el-form-item label="活动产品" label-width="80px">
              <vxe-table class="item-table" :data="item.bpSchemeProductList">
                <vxe-table-column title="商品编码" field="productCode" min-width="100"></vxe-table-column>
                <vxe-table-column title="商品名称" field="productName" min-width="100"></vxe-table-column>
              </vxe-table>
            </el-form-item>
            <el-form-item label="活动赠品" label-width="80px">
              <vxe-table class="item-table" :data="item.zpSchemeProductList" border>
                <vxe-table-column title="商品编码" field="productCode" min-width="100"></vxe-table-column>
                <vxe-table-column title="商品名称" field="productName" min-width="100"></vxe-table-column>
              </vxe-table>
            </el-form-item>
            <el-form-item label="套餐备注" label-width="80px">
              <el-input type="textarea" v-model="item.remarks" readonly></el-input>
            </el-form-item>
          </el-form>
        </div>
      </template>
      <div v-if="select.actSchemePictureList&&select.actSchemePictureList.length>0">
        <el-form>
          <el-form-item label="附件" label-width="100px">
            <Upload :limit="1" :value="select.actSchemePictureList || []" :disabled="disabled" fileMsg="只能上传pdf文件" accept=".pdf"></Upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <Modal :modalConfig="modalConfig" @onClose="dialog_btnClose">
      <div>
        <vxe-table :data="tableList" :loading="tableLoading" border row-id="schemeCode" class="vxeTable" @radio-change="dialog_radioChange" :radio-config="{checkRowKey: select?select.schemeCode:''}">
          <vxe-table-column type="radio" width="60"></vxe-table-column>
          <vxe-table-column field="schemeCode" title="方案编码" min-width="100"></vxe-table-column>
          <vxe-table-column field="schemeName" title="方案名称" min-width="100"></vxe-table-column>
        </vxe-table>
        <div class="page-pageination">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="tablePage.currentPage"
            :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
            :page-size="tablePage.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="tablePage.totalResult"
          >
          </el-pagination>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button type="primary" @click="dialog_btnConfirm" size="small" icon="el-icon-check">提交 </el-button>
        <el-button type="danger" @click="dialog_btnClose" size="small" icon="el-icon-close">关闭</el-button>
      </div>
    </Modal>
  </div>
</template>
<script>
import Modal from '../../../../../../../components/modal';
import request from '../../../../../../../utils/request';
import Upload from '../../../../../../../components/form/components/form_upload.vue';

export default {
  name: 'base_info',
  components: {
    Modal,
    Upload,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val) {
          this.select = val;
          this.select.schemeTime = this.select.schemeStartTime && this.select.schemeEndTime ? `${this.select.schemeStartTime} 至 ${this.select.schemeEndTime}` : '';
        }
      },
    },
  },
  data() {
    return {
      // 弹窗配置项
      modalConfig: {
        title: '选择方案',
        visible: false,
        type: 'Modal',
        width: '50%',
      },
      tablePage: {
        currentPage: 1,
        pageSize: 15,
        totalResult: 0,
      },
      tableLoading: false,
      tableList: [],
      selectTable: null,
      select: null,
    };
  },
  methods: {
    /** @desc 查询可申请的活动方案列表 * */
    getList() {
      const params = {
        pageNum: this.tablePage.currentPage,
        pageSize: this.tablePage.pageSize,
      };
      this.tableLoading = true;
      request.post('/sfa/sfaActSchemeController/findPosList', params).then((res) => {
        this.tableLoading = false;
        this.tablePage.totalResult = res.result.count;
        this.tableList = res.result.data || [];
      });
    },
    /** @desc 点击选择方案按钮 * */
    showDialog() {
      this.modalConfig.visible = true;
      this.getList();
    },
    /** @desc table选择 * */
    dialog_radioChange(val) {
      this.selectTable = val.row;
    },
    /** @desc 分页size * */
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.getList();
    },
    /** @desc 分页page * */
    handleCurrentChange(val) {
      this.tablePage.currentPage = val;
      this.getList();
    },
    /** @desc 点击提交按钮 * */
    dialog_btnConfirm() {
      if (!this.selectTable) {
        this.$message.warning('请选择一条方案！');
        return false;
      }
      this.select = this.selectTable;
      this.select.schemeTime = this.select.schemeStartTime && this.select.schemeEndTime ? `${this.select.schemeStartTime}至${this.select.schemeEndTime}` : '';
      this.modalConfig.visible = false;
      this.$emit('input', this.select);
    },
    /** @desc 点击关闭按钮 * */
    dialog_btnClose() {
      this.modalConfig.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.info{
  padding-top: 20px;
}
.item{
  width: 100%;
  padding: 10px 20px;
  margin-bottom: 10px;
  box-sizing: border-box;
  background-color: #f2f2f2;
  .item-name{
    font-size: 14px;
  }
}
/deep/ .page-pageination {
  text-align: right;
  padding-top: 10px;
  .el-input {
    display: inline-block !important;
  }
}
</style>
