var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c(
            "el-button",
            {
              attrs: { type: "primary", icon: "el-icon-plus" },
              on: { click: _vm.showDialog },
            },
            [_vm._v("选择方案")]
          )
        : _vm._e(),
      _vm.select
        ? _c(
            "div",
            { staticClass: "info" },
            [
              _c(
                "el-form",
                [
                  _c(
                    "el-row",
                    { attrs: { gutter: 20 } },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案编码",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.schemeCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "schemeCode", $$v)
                                  },
                                  expression: "select.schemeCode",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案名称",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.schemeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "schemeName", $$v)
                                  },
                                  expression: "select.schemeName",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案类型",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.schemeTypeDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "schemeTypeDesc", $$v)
                                  },
                                  expression: "select.schemeTypeDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "门店类型",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.terminalTypeDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.select,
                                      "terminalTypeDesc",
                                      $$v
                                    )
                                  },
                                  expression: "select.terminalTypeDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "陈列类型",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.displayTypeDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "displayTypeDesc", $$v)
                                  },
                                  expression: "select.displayTypeDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 8 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案执行时间",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { readonly: "" },
                                model: {
                                  value: _vm.select.schemeTime,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "schemeTime", $$v)
                                  },
                                  expression: "select.schemeTime",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: {
                                label: "方案描述",
                                "label-width": "100px",
                              },
                            },
                            [
                              _c("el-input", {
                                attrs: { type: "textarea", readonly: "" },
                                model: {
                                  value: _vm.select.schemeDesc,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.select, "schemeDesc", $$v)
                                  },
                                  expression: "select.schemeDesc",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._l(
                _vm.select.actSchemeSetmealList || [],
                function (item, index) {
                  return [
                    _c(
                      "div",
                      { key: index, staticClass: "item" },
                      [
                        _c("div", { staticClass: "item-name" }, [
                          _vm._v("套餐" + _vm._s(index + 1)),
                        ]),
                        _c(
                          "el-form",
                          [
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "活动产品",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c(
                                  "vxe-table",
                                  {
                                    staticClass: "item-table",
                                    attrs: { data: item.bpSchemeProductList },
                                  },
                                  [
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "商品编码",
                                        field: "productCode",
                                        "min-width": "100",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "商品名称",
                                        field: "productName",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "活动赠品",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c(
                                  "vxe-table",
                                  {
                                    staticClass: "item-table",
                                    attrs: {
                                      data: item.zpSchemeProductList,
                                      border: "",
                                    },
                                  },
                                  [
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "商品编码",
                                        field: "productCode",
                                        "min-width": "100",
                                      },
                                    }),
                                    _c("vxe-table-column", {
                                      attrs: {
                                        title: "商品名称",
                                        field: "productName",
                                        "min-width": "100",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "el-form-item",
                              {
                                attrs: {
                                  label: "套餐备注",
                                  "label-width": "80px",
                                },
                              },
                              [
                                _c("el-input", {
                                  attrs: { type: "textarea", readonly: "" },
                                  model: {
                                    value: item.remarks,
                                    callback: function ($$v) {
                                      _vm.$set(item, "remarks", $$v)
                                    },
                                    expression: "item.remarks",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                }
              ),
              _vm.select.actSchemePictureList &&
              _vm.select.actSchemePictureList.length > 0
                ? _c(
                    "div",
                    [
                      _c(
                        "el-form",
                        [
                          _c(
                            "el-form-item",
                            {
                              attrs: { label: "附件", "label-width": "100px" },
                            },
                            [
                              _c("Upload", {
                                attrs: {
                                  limit: 1,
                                  value: _vm.select.actSchemePictureList || [],
                                  disabled: _vm.disabled,
                                  fileMsg: "只能上传pdf文件",
                                  accept: ".pdf",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _c(
        "Modal",
        {
          attrs: { modalConfig: _vm.modalConfig },
          on: { onClose: _vm.dialog_btnClose },
        },
        [
          _c(
            "div",
            [
              _c(
                "vxe-table",
                {
                  staticClass: "vxeTable",
                  attrs: {
                    data: _vm.tableList,
                    loading: _vm.tableLoading,
                    border: "",
                    "row-id": "schemeCode",
                    "radio-config": {
                      checkRowKey: _vm.select ? _vm.select.schemeCode : "",
                    },
                  },
                  on: { "radio-change": _vm.dialog_radioChange },
                },
                [
                  _c("vxe-table-column", {
                    attrs: { type: "radio", width: "60" },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "schemeCode",
                      title: "方案编码",
                      "min-width": "100",
                    },
                  }),
                  _c("vxe-table-column", {
                    attrs: {
                      field: "schemeName",
                      title: "方案名称",
                      "min-width": "100",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "page-pageination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.tablePage.currentPage,
                      "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
                      "page-size": _vm.tablePage.pageSize,
                      layout: "total, sizes, prev, pager, next, jumper",
                      total: _vm.tablePage.totalResult,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "dialog-footer" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    type: "primary",
                    size: "small",
                    icon: "el-icon-check",
                  },
                  on: { click: _vm.dialog_btnConfirm },
                },
                [_vm._v("提交 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    type: "danger",
                    size: "small",
                    icon: "el-icon-close",
                  },
                  on: { click: _vm.dialog_btnClose },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }