<script>
import Form, { formCreate } from '../../../../../../components/form';
import request from '../../../../../../utils/request';

import BaseInfo from './components/base_info.vue';
import JoinTable from './components/join_table.vue';

formCreate.component('BaseInfo', BaseInfo);
formCreate.component('JoinTable', JoinTable);

export default {
  extends: Form,
  components: {
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      rule: [],
      row: null,
    };
  },
  async created() {
    const that = this;
    this.buttons.submitAudit = true;
    this.buttonText.submitAudit = '提交';
    this.buttonText.submit = '暂存';
    const rule = await this.getFormRule('sfa_activity_activityProgram_add', 'CRM20210304000001348');
    rule.forEach((item) => {
      const v = item;
      if (v.field === 'datePicker') {
        v.props = {
          ...v.props,
          type: 'daterange',
          rangeSeparator: '至',
          startPlaceholder: '开始日期',
          endPlaceholder: '结束日期',
        };
      } else if (v.field === 'scheme') {
        v.col = { ...v.col, labelWidth: '0' };
        v.on = {
          input(val) {
            that.setLimit(val);
            that.setValue({
              terminalTypeList: null,
              displayTypeList: null,
              datePicker: null,
            });
          },
        };
      } else if (v.field === 'asJoinList') {
        v.on = {
          ...v.on,
          pageChange(val) {
            that.getJoinList(val);
          },
        };
      }
      if (this.formConfig.type === 'view') {
        v.props = {
          ...v.props,
          disabled: true,
        };
        this.buttons.submit = false;
        this.buttons.submitAudit = false;
      }
    });
    if (this.formConfig.type === 'view') {
      this.hiddenFields(false, ['title3', 'asJoinList']);
    } else {
      this.hiddenFields(true, ['title3', 'asJoinList']);
    }
    this.rule = rule;
    this.reload(this.rule);
    if (this.formConfig.type === 'edit' || this.formConfig.type === 'view' || this.id) {
      request.get('/sfa/sfaActSchemePosApplyController/queryDetailById', { id: this.formConfig.id || this.id }).then((res) => {
        if (res.success) {
          const { result } = res;
          result.datePicker = [result.activityStartTime, result.activityEndTime];
          const scheme = result.sfaActSchemeRespVo;
          this.setValue(result);
          this.setValue({ scheme });
          this.row = res.result;
          this.setLimit(scheme);
          this.getJoinList();
        }
      });
    }
  },
  methods: {
    // 获取参与信息
    getJoinList(page) {
      request.post('/sfa/sfaActSchemePosApplyController/parList', { id: this.formConfig.id || this.id, ...page }).then((res) => {
        if (!res.success || !res.result) return false;
        const { result } = res;
        this.setValue({
          asJoinList: result || {},
        });
      });
    },
    // 设置限制
    setLimit(scheme) {
      // 限制所选门店类型
      const terminalTypeOptions = [];
      if (scheme.terminalTypeDescList && scheme.terminalTypeDescList.length > 0) {
        scheme.terminalTypeDescList.map((v, k) => {
          terminalTypeOptions.push({
            label: v,
            value: scheme.terminalTypeList[k],
          });
          return v;
        });
      }
      const terminalTypeRule = this.getRule('terminalTypeList');
      terminalTypeRule.dictCode = '';
      terminalTypeRule.options = terminalTypeOptions;
      terminalTypeRule.optionsKey = {
        label: 'label',
        value: 'value',
      };
      this.setRule('terminalTypeList', terminalTypeRule);
      // 限制所选陈列类型
      const displayTypeOptions = [];
      if (scheme.displayTypeDescList && scheme.displayTypeDescList.length > 0) {
        scheme.displayTypeDescList.map((v, k) => {
          displayTypeOptions.push({
            label: v,
            value: scheme.displayTypeList[k],
          });
          return v;
        });
      }
      const displayTypeRule = this.getRule('displayTypeList');
      displayTypeRule.dictCode = '';
      displayTypeRule.options = displayTypeOptions;
      displayTypeRule.optionsKey = {
        label: 'label',
        value: 'value',
      };
      this.setRule('displayTypeList', displayTypeRule);
      // 限制选择活动时间
      const pickerOptions = {
        disabledDate: (time) => {
          const startTime = scheme.schemeStartTime;
          const endTime = scheme.schemeEndTime;
          if (startTime && startTime) {
            return (time.getTime() < new Date(startTime).getTime() - 8.64e7 || time.getTime() > new Date(endTime).getTime());
          }
          return time.getTime() < Date.now() - 8.64e7;
        },
      };
      const datePickerRule = this.getRule('datePicker');
      datePickerRule.props = {
        ...datePickerRule.props,
        pickerOptions,
      };
      this.setRule('datePicker', datePickerRule);
    },
    // 提交
    submit(type) {
      const formData = this.getFormData();
      if (!formData) return false;
      let params = formData;
      if (!formData.scheme) {
        this.$message.warning('请选择活动方案');
        return false;
      }
      params.schemeCode = formData.scheme ? formData.scheme.schemeCode : null;
      params.activityStartTime = formData.datePicker ? formData.datePicker[0] : null;
      params.activityEndTime = formData.datePicker ? formData.datePicker[1] : null;
      delete params.scheme;
      delete params.datePicker;

      if (type === 1) {
        // 暂存
        params.isSubmit = '0';
      } else {
        // 提交
        params.isSubmit = '1';
      }
      let url = '/sfa/sfaActSchemePosApplyController/save';
      if (this.formConfig.type === 'edit') {
        url = '/sfa/sfaActSchemePosApplyController/update';
        params = Object.assign(this.row, formData);
      }

      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message({
            type: 'success',
            message: '操作成功',
          });

          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
</script>
