<template>
  <div>
    <vxe-table :data="list" border>
      <vxe-table-column field="terminalCode" title="终端编码" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="terminalName" title="终端名称" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="terminalAddress" title="终端地址" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="contactName" title="终端联系人" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="contactPhone" title="终端联系方式" min-width="100" show-overflow></vxe-table-column>
      <vxe-table-column field="parDesc" title="活动参与情况" min-width="100" show-overflow></vxe-table-column>
    </vxe-table>
    <div class="page-pageination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="tablePage.pageNum"
        :page-sizes="[5, 10, 15, 20, 50, 100, 150, 200]"
        :page-size="tablePage.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tablePage.total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    value: {
      deep: true,
      immediate: true,
      handler(val) {
        if (val && val.count) {
          this.tablePage.total = val.count;
        }
        if (val && Array.isArray(val.data)) {
          this.list = val.data || [];
        }
      },
    },
  },
  data() {
    return {
      list: [],
      tablePage: {
        pageNum: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  methods: {
    // 页数改变
    handleCurrentChange(val) {
      this.tablePage.pageNum = val;
      this.$emit('pageChange', this.tablePage);
    },
    // size改变
    handleSizeChange(val) {
      this.tablePage.pageSize = val;
      this.$emit('pageChange', this.tablePage);
    },
  },
};
</script>
<style  lang="less" scoped>
/deep/ .page-pageination {
  text-align: right;
  padding-top: 10px;
  .el-input {
    display: inline-block !important;
  }
}
</style>
