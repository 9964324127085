var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vxe-table",
        { attrs: { data: _vm.list, border: "" } },
        [
          _c("vxe-table-column", {
            attrs: {
              field: "terminalCode",
              title: "终端编码",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "terminalName",
              title: "终端名称",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "terminalAddress",
              title: "终端地址",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "contactName",
              title: "终端联系人",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "contactPhone",
              title: "终端联系方式",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
          _c("vxe-table-column", {
            attrs: {
              field: "parDesc",
              title: "活动参与情况",
              "min-width": "100",
              "show-overflow": "",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "page-pageination" },
        [
          _c("el-pagination", {
            attrs: {
              "current-page": _vm.tablePage.pageNum,
              "page-sizes": [5, 10, 15, 20, 50, 100, 150, 200],
              "page-size": _vm.tablePage.pageSize,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.tablePage.total,
            },
            on: {
              "size-change": _vm.handleSizeChange,
              "current-change": _vm.handleCurrentChange,
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }